#services h5 {
  color: #000;
}

.services-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2rem;
}

.service {
  background: transparent;
  color: #000;
  border-radius: 0 0 2rem 2rem;
  border: 1px solid var(--color-primary);
  height: fit-content;
  transition: var(--transition);
}

.service-head {
  background: var(--color-primary);
  padding: 2rem;
  border-radius: 0 0 2rem 2rem;
  box-shadow: 0 2rem 1rem rgba(0, 0, 0, 0.1)
}

.service-head h3 {
  color: var(--color-bg);
  font-size: 1rem;
  text-align: center;
}

.service-list {
  padding: 2rem;
}

.service-list li {
  display: flex;
  gap: 1rem;
  margin-bottom: 0.8rem;
  align-items: center;
  justify-content: center;
}

.service-list-icon {
  color: var(--color-primary);
  margin-top: 2px;
}

.service-list p {
  font-size: 0.9rem;
}


/*  ------------------------------ Media Queries = Medium Devices ----------------------------------  */
@media screen and (max-width: 1024px) {
  .services-container {
    grid-template-columns: 1fr 1fr;
    gap: 2rem;
  }

  .service {
    height:  auto;
  }
}

/*  ------------------------------ Media Queries = Small Devices ----------------------------------  */
@media screen and (max-width: 600px) {
  .services-container {
    grid-template-columns: 1fr;
    gap: 1.5rem;
  }
}
.about-container {
  display: grid;
  grid-template-columns: 35% 50%;
  gap: 15%;
}

.container-wrapper {
  animation: showComponent 3s;
}

#about h5 {
  color: #000;
}

.about-me {
  width: 200px;
  height: 200px;
  aspect-ratio: 1/1;
  border-radius: 2rem;
  background: linear-gradient(45deg, transparent, var(--color-primary), transparent);
  display: grid;
  place-items: center;
  cursor: pointer;
}

.about-me-img {
  border-radius: 2rem;
  overflow: hidden;
  transform: rotate(10deg);
  transition: var(--transition);
}

.about-me-img img {
  width: 190px;
  height: 190px;
}

.about-me-img:hover {
  transform: rotate(0);
}

.about-cards {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1.5rem;
}

.about-card {
  opacity: 0.8;
  border: 1px solid transparent;
  border-radius: 1rem;
  padding: 1rem;
  text-align: center;
  border-color: var(--color-primary-variant);
  cursor: pointer !important;
}

.about-card:hover {
  background: transparent;
  border-color: var(--color-primary-variant);
  cursor: default;
}

.about-icon {
  color: var(--color-primary);
  font-size: 1.4rem;
  margin-bottom: 1rem;
}

.about-card h5 {
  font-size: 0.95rem;
}

.about-card small {
  font-size: 0.7rem;
  color: #121212;
}

.about-content p {
  margin: 2rem 0 2.6rem;
  color: #000;
  text-align: justify;
}

.btn-about-me {
  background: var(--color-primary);
  width: max-content;
  display: inline-block;
  color: #fff;
  padding: 0.75rem 1.2rem;
  border-radius: 0.4rem;
  cursor: pointer;
  border: 1px solid var(--color-primary);
}

/*  ------------------------------ Media Queries = Medium Devices ----------------------------------  */
@media screen and (max-width: 1024px) {
  .about-container {
    grid-template-columns: 1fr;
    gap: 0;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .about-me {
    width: 50%;
    margin: 2rem auto 4rem;
  }

  .about-content p {
    margin: 1rem 0 1.5rem;
    text-align: center;
  }
}

/*  ------------------------------ Media Queries = Small Devices ----------------------------------  */
@media screen and (max-width: 600px) {
  .about-me {
    width: 65%;
    margin: 0 auto 3rem;
    height: 320px
  }

  .about-me-img img {
    width: 100%;
    height: 300px;
  }

  .about-cards {
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
  }

  .about-content {
    text-align: center;
  }

  .about-content p {
    margin: 1.5rem 0;
    text-align: justify;
  }
}

@keyframes showComponent {
  0% {
    transform: translateY(100px);
  }

  100% {
    transform: translateY(0);
  }
}


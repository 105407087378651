#portifolio h5 {
  color: #000;
}

.portifolio-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2.5rem;
}

.portifolio-item {
  background: transparent;
  padding: 1.2rem;
  border-radius: 2rem;
  border: 1px solid var(--color-primary-variant);
  transition: var(--transition)
}

.portifolio-item h3 {
  color: #000;
}

.portifolio-item:hover {
  border-color: var(--color-primary-variant);
  background: transparent;
}

.portifolio-item-img {
  border-radius: 1.5rem;
  overflow: hidden;
}

.portifolio-item h3 {
  margin: 1.2rem 0rem 2rem;
}

.link-repos {
  margin-top: 1.5rem;
  color: var(--color-primary-variant);
}

.coffe-link {
  font-size: 0.8rem;
  margin-top: -1.5rem;
  margin-bottom: 0.5rem;
  text-align: justify;
}

/*  ------------------------------ Media Queries = Medium Devices ----------------------------------  */
@media screen and (max-width: 1024px) {
  .portifolio-container {
    grid-template-columns: 1fr 1fr;
    gap: 1.2rem;
  }
  
}

/*  ------------------------------ Media Queries = Small Devices ----------------------------------  */
@media screen and (max-width: 600px) {
  .portifolio-container {
    grid-template-columns: 1fr;
    gap: 1rem;
  }
}



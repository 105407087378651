#contact h5 {
  color: var(--color-black);
}

.contact-container {
  width: 58%;
  display: grid;
  grid-template-columns: 30% 58%;
  gap: 12%;
}

.contact-options {
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
}

.contact-option {
  background: transparent;
  padding: 1.2rem;
  border-radius: 1.2rem;
  text-align: center;
  border: 1px solid var(--color-primary-variant);
  transition: var(--transition);
}

.contact-option svg {
  color: var(--color-black);  
}

.contact-option h4 {
  color: var(--color-black);
}

.contact-option:hover {
  background: transparent;
  border-color: var(--color-primary);
}

.contact-option-icon {
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
}

.contact-option a {
  margin-top: 0.7rem;
  display: inline-block;
  font-size: 0.8rem;
}

.btn-submit-form {
  max-width: 120px;
  width: 100%;
  font-size: 14px;
  background-color: var(--color-primary);
  height: 45px;
  padding: 0 1rem;
  border-radius: 10px;
  cursor: pointer;
  color: #fff;
}

/*========================= Form ========================= */
form {
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
}

input, textarea {
  width: 100%;
  padding: 1rem;
  border-radius: 0.5rem;
  background-color: transparent;
  border: 2px solid var(--color-primary-variant);
  resize: none;
  color: var(--color-black);
}

/*  ------------------------------ Media Queries = Medium Devices ----------------------------------  */
@media screen and (max-width: 1024px) {
  .contact-container {
    grid-template-columns: 1fr;
    gap: 2rem;
  }
}

/*  ------------------------------ Media Queries = Small Devices ----------------------------------  */
@media screen and (max-width: 600px) {
  .contact-container {
    width: var(--container-width-sm);
  }
}




header {
  height: 100vh;
  padding-top: 7rem;
  overflow: hidden;
  /* background-image: url('../../assets//bg-home.jpg'); */
  /* background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0, rgba(0, 0, 0, 0) 40%, #000 100%);
  background: -moz-linear-gradient(to bottom, rgba(0, 0, 0, 0) 0, rgba(0, 0, 0, 0) 40%, #000 100%);
  background: -o-linear-gradient(to bottom, rgba(0, 0, 0, 0) 0, rgba(0, 0, 0, 0) 40%, #000 100%);
  background: -webkit-linear-gradient(to bottom, rgba(0, 0, 0, 0) 0, rgba(0, 0, 0, 0) 40%, #000 100%); */
}

.header-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 85%;
  position: relative;
}

.header-container-info {
  margin-top: -6rem;
}

.header-container-info h5 {
  color: var(--color-black);
}

.header-container-info h2 {
  color: var(--color-black);
}

/* ============================== CTA =================================== */
.cta {
  margin-top: 2.5rem;
  display: flex;
  gap: 1.2rem;
  justify-content: center;
}

/* ============================== Header Social =================================== */
.header-socials {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
  position: absolute;
  left: 1px;
  margin-top: 2rem;
}
/* ============================== Me =================================== */
.me {
  background: linear-gradient(var(--color-primary), transparent);
  width: 22rem;
  height: 24rem;
  position: absolute;
  left: calc(80% - 11rem);
  border-radius: 3rem;
  margin-top: 4rem;
  margin-bottom: 6rem;
  overflow: hidden;
  padding: 5rem 1.5rem 1.5rem;
  animation: go-up 3s;
  animation-duration: 5s ease-in-out;
}

@keyframes go-up {
  0% {
    transform: translateY(100px);
  }

  100% {
    transform: translateY(0);
  }
}

/* ============================== Scroll Down =================================== */
.scroll-down {
  position: absolute;
  right: -2.3rem;
  bottom: 5rem;
  transform: rotate(90deg);
  font-weight: 300;
  font-size: 0.9rem;
}

/*  ------------------------------ Media Queries = Medium Devices ----------------------------------  */
@media screen and (max-width: 1024px) {
  header {
    height: 68vh;
  }
  
}

/*  ------------------------------ Media Queries = Small Devices ----------------------------------  */
@media screen and (max-width: 600px) {
  header {
    height: 100vh;
  }

  .me {
    display: none;
  }

  .header-socials,
  .scroll-down {
    display: none;
  }
}